import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import Link from '../../components/Common/Link'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const Photo360ForRealEstate: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="Продажа недвижимости по панорамным фотографиям"
        description="Цифровые инструменты для продажи недвижимости и удаленного контроля ремонтных работ. Подписи к фоткам указал в скобках в файле с текстом"
      />
      <NewsSection
        title="Преимущества Фото360 при продаже недвижимости и отслеживания хода ремонтных работ"
        dateData={{ date: '08.09.2023' }}
        rayContent
        maxContent
      >
        <p className={s.text}>
          В современных условиях интернет играет важную роль в процессе продажи
          квартир. Но в динамично меняющемся мире, когда при равных категориях
          объектов недвижимости конкуренция в цене уже не так сильно играет
          роль, девелоперы соревнуются в том, кто из них предоставит более
          крутой и комфортный сервис клиенту, тем временем клиенты, отчасти,
          руководствуется тем, что им удобнее, интереснее и что затрачивает
          меньше времени.
        </p>
        <p className={s.text}>
          Даже смело можно сказать, что в первую очередь, клиенты дорожат своим
          временем, они стали более занятыми, они проживают в других регионах
          или странах и у них просто нет возможности потратить свое время на
          живой осмотр квартир или отслеживать ход отделочных работ. Поэтому
          предоставить им более подробную информацию и цифровой доступ к
          недвижимости будет правильным решением для будущей сделки.
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/photo360-for-real-estate/image-1.png"
            alt="Web и мобильный интерфейс Фото360"
            title="Web и мобильный интерфейс Фото360"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Уже сейчас сложно представить стройку без использования мобильных
          устройств или цифровых приложений для контроля. Наш продукт SIGNAL
          INSPECTION позволяет контролировать ход строительных работ из любой
          точки мира, наши клиенты активно используют его в своей работе,
          отслеживают прогресс стройки, выдают замечания прямо из своих
          смартфонов и при этом не всегда посещая объекты. Это все про стройку,
          но SIGNAL INSPECTION это еще и эффективный инструмент для продажи
          недвижимости или контроля покупателями хода отделочных работ. В данной
          статье я хочу рассмотреть преимущества использования возможностей
          INSPECTION в процессе продажи недвижимости.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          1. Повышение привлекательности недвижимости на сайтах застройщиков
        </h3>
        <p className={s.text}>
          Фото360 позволяют потенциальным покупателям более полно представить
          себе объект недвижимости. Вместо обычных статичных изображений или
          планов, эти фотографии позволяют создать{' '}
          <Link
            href="https://pa.sgnl.pro/public/photo/kavya"
            target="_blank"
            className="has-text-primary"
            rel="nofollow noreferrer"
          >
            ощущение присутствия
          </Link>{' '}
          на месте и позиционировать себя в квартире. В результате, объекты с
          такими фотографиями привлекают больше внимания покупателей и вызывают
          больший интерес.
        </p>
        <div className={s.columnWrapper}>
          <div>
            <h3 className={cn('font-w-600', s.text)}>
              2. Увеличение доверия потенциальных покупателей
            </h3>
            <p className={s.text}>
              SIGNAL INSPECTION предоставляет потенциальным покупателям
              возможность более детально исследовать объект недвижимости. Это
              позволяет им составить более точное представление о его качестве и
              состоянии, что в свою очередь способствует повышению доверия к
              застройщику. Покупатели могут визуально изучить все углы и
              особенности объекта, не выходя из дома.
            </p>
            <p className={s.text}>
              Вот по этому фото{' '}
              <Link
                href="https://pa.sgnl.pro/public/photo/bqw3b"
                target="_blank"
                className="has-text-primary"
                rel="nofollow noreferrer"
              >
                https://pa.sgnl.pro/public/photo/bqw3b
              </Link>{' '}
              мы принимали наш стенд на BIM Форум`23
            </p>
          </div>
          <div className="flex w-100">
            <StaticImage
              className="mx-auto"
              src="../../assets/images/news/photo360-for-real-estate/image-2.png"
              alt="Приемка стенда SIGNAL по Фото360"
              title="Приемка стенда SIGNAL по Фото360"
              placeholder="blurred"
            />
          </div>
        </div>
        <h3 className={cn('font-w-600', s.text)}>
          3. Экономия времени и сокращение количества просмотров
        </h3>
        <p className={s.text}>
          Благодаря Фото360 покупатели могут получить более полное представление
          о объекте недвижимости, выдать замечания к выполнению отделочных работ
          еще до посещения его лично. Это позволяет сэкономить время как
          покупателям, так и строителям.
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/photo360-for-real-estate/image-3.png"
            alt="Создание комментариев в INSPECTION"
            title="Создание комментариев в INSPECTION"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Также, благодаря таким фотографиям, количество лишних просмотров
          значительно сокращается, поскольку покупатели уже заранее оценивают
          объект и могут отсеивать неподходящие варианты.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          4. Увеличение конверсии и скорости продаж
        </h3>
        <p className={s.text}>
          Возможности INSPECTION значительно повышают вероятность продажи
          объекта недвижимости. Любой риелтор подтвердит, что если Вы привели
          клиента на объект, то шанс успешной сделки в разы выше. При этом,
          замотивировать клиента выделить время и приехать на объект - очень
          сложная задача. Фото360 дает эффект присутствия на объекте близкий к
          реальному, не заставляя куда-то ехать, без остановки работ и провода
          клиентов через стройплощадку мимо рабочих. Клиент может побывать на
          объекте в любое удобное для него время, в 1 клик, со своего смартфона.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          5. Эффективное маркетинговое средство
        </h3>
        <p className={s.text}>
          Использование Фото 360 стало эффективным маркетинговым инструментом
          для привлечения покупателей. В современном конкурентном рынке
          недвижимости, предлагать более интересные и цифровые инструменты может
          сыграть важную роль в поиске покупателей. Более проработанный и
          качественный маркетинг может привлечь больше потенциальных покупателей
          и, соответственно, увеличить шансы на успешную продажу недвижимости.
        </p>
        <p className={s.text}>
          SIGNAL INSPECTION очень прост в работе и любой специалист может в пару
          действий осуществить съемку, необходимо только наличие камеры, которая
          позволяет делать сферические кадры (мы используем в работе камеры
          Ricoh Theta) и наше приложение SIGNAL PHOTOMAKER
        </p>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/photo360-for-real-estate/image-4.png"
            alt="Мобильное приложение SIGNAL INSPECTION"
            title="Мобильное приложение SIGNAL INSPECTION"
            placeholder="blurred"
          />
        </div>
        <h2 className={s.textTitle}>
          Возможные кейсы использования SIGNAL INSPECTION при продажи
          недвижимости
        </h2>
        <ul className={cn(s.textLists, s.disc)}>
          <li>
            Размещение точек съемки на планах квартир и прочей недвижимости, при
            их продаже или аренде, на сайтах застройщиков и арендодателей
          </li>
          <li>
            Как дополнительная услуга для покупателей, по отслеживанию хода
            выполнения отделочных работ. Это удобно для покупателей, которые не
            имеют достаточно времени, чтобы еженедельно посещать объекты.
          </li>
          <li>
            Как конкурентное преимущество при продаже недвижимости иностранным
            клиентам, которые в силу иммиграционных правил не могут часто
            посещать объекты и отслеживать ход работ
          </li>
          <li>
            В малоэтажном строительстве. На своем опыте убедился как важно
            контролировать строительство коттеджа в режиме 24/7, многие
            специалисты закрывают глаза на важные узлы или вовсе не знают, как
            правильно это сделать, даже если они проверенные и вы с ними давно
            работаете. Предоставление клиентам доступ к Фото360 позволяет им
            быть в курсе всех этапов работ и отслеживать правильность их
            выполнения, а также увидеть те моменты, которые на видео сложно
            отследить. А для компаний подобный сервис может быть использован,
            как дополнительная финансовая услуга или же как конкурентное
            преимущество.
          </li>
        </ul>
        <div className="flex">
          <StaticImage
            className="mx-auto my-4"
            src="../../assets/images/news/photo360-for-real-estate/image-5.png"
            alt="Контроль изменений по Фото360"
            title="Контроль изменений по Фото360"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Технология Фото360 активно используется во всем мире. Первыми, этот
          функционал взяли за основу для создания панорам городов и улиц в
          картах. Около 5 лет назад, Фото360 начали внедрять иностранные
          строительные компании, для удаленного контроля за ходом выполнения
          СМР. И сейчас Фото360 довольно быстро набирает популярность при
          продажи недвижимости, получения конкурентного преимущества и
          привлечения более качественных покупателей. Наш{' '}
          <GatsbyLink
            to={getPath(PathEnum.Inspection)}
            className="has-text-primary"
          >
            SIGNAL INSPECTION
          </GatsbyLink>{' '}
          превратит ваш сайт в маркетплейс по продаже жилья, это новый стандарт
          для цифровизации девелопмента и скоро клиенты не будут рассматривать
          какой-либо ЖК, если у него нет Экскурсии по Фото360.
        </p>
      </NewsSection>
    </>
  )
}

export default Photo360ForRealEstate
